<template>
  <div>
    <Navbar />
    <main class="main-layout pb-5">
      <transition mode="out-in" name="fade">
        <router-view :key="$router.fullPath"></router-view>
      </transition>
    </main>
    <Totop />
    <Footer />

  </div>
</template>

<script>
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Totop from '../components/Totop';

export default {
  name: 'Layout',
  components: {
    Footer,
    Navbar,
    Totop,
  },
};
</script>
